import React from "react"
import Employee from "./employee"
import { StaticQuery, graphql } from "gatsby"
import { MobileRule } from "../constants"

/** @jsx jsx */
import { jsx } from "@emotion/core"

class Employees extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            jennie: file(relativePath: { eq: "employees/jennie.jpg" }) {
              childImageSharp {
                fixed(width: 260, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            linda: file(relativePath: { eq: "employees/linda.jpg" }) {
              childImageSharp {
                fixed(width: 260, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            petra: file(relativePath: { eq: "employees/petra.jpg" }) {
              childImageSharp {
                fixed(width: 260, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            frida: file(relativePath: { eq: "employees/frida.jpg" }) {
              childImageSharp {
                fixed(width: 260, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            therese: file(relativePath: { eq: "employees/therese.jpg" }) {
              childImageSharp {
                fixed(width: 260, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            sandra: file(relativePath: { eq: "employees/sandra.jpg" }) {
              childImageSharp {
                fixed(width: 260, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            aida: file(relativePath: { eq: "employees/aida.jpg" }) {
              childImageSharp {
                fixed(width: 260, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            mikaela: file(relativePath: { eq: "employees/mikaela.jpg" }) {
              childImageSharp {
                fixed(width: 260, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            ida: file(relativePath: { eq: "employees/ida.jpg" }) {
              childImageSharp {
                fixed(width: 260, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <section
            css={{
              padding: "70px 4rem 1rem 4rem",
              [MobileRule]: { padding: "2rem 2.5rem 4rem 2.5rem" },
            }}
          >
            <div
              css={{
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: "560px",
              }}
            >
              <h2
                css={{
                  textAlign: "center",
                  fontSize: "2rem",
                  color: "#FF8479",
                }}
              >
                Vi är Clearly
              </h2>
              <p css={{ marginTop: 0, marginBottom: "4rem" }}>
                Vi gör det vi är bäst på så att ni kan frigöra mer tid till det
                ni är bäst på. Med personlig och kompentent service tar vi hand
                om er verksamhet på bästa och mest effektiva sätt.
              </p>
            </div>
            <div
              css={{
                [MobileRule]: { justifyContent: "space-around" },
                justifyContent: "center",
                maxWidth: "1280px",
                margin: "auto",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Employee
                image={data.linda.childImageSharp.fixed}
                role="VD"
                name="Linda Sjögren"
                mail="linda@clearlyab.se"
                phone={"070-745 57 33"}
              />
              <Employee
                image={data.therese.childImageSharp.fixed}
                role="Redovisningskonsult"
                name="Therese Lagerström"
                mail="therese.lagerstrom@clearlyab.se"
                phone={"072-253 44 95"}
              />
              <Employee
                image={data.sandra.childImageSharp.fixed}
                role="Redovisningskonsult"
                name="Sandra Emanuelsson"
                mail="sandra.emanuelsson@clearlyab.se"
                phone={"073-968 25 80"}
              />
              <Employee
                image={data.petra.childImageSharp.fixed}
                role="Redovisningskonsult"
                name="Petra Johansson"
                mail="petra@clearlyab.se"
                phone={"070-097 97 47"}
              />
              <Employee
                image={data.jennie.childImageSharp.fixed}
                role="Redovisningskonsult"
                name="Jennie Geschwind Ström"
                mail="jennie.strom@clearlyab.se"
                phone={"073-941 40 27"}
              />
              <Employee
                image={data.frida.childImageSharp.fixed}
                name="Frida Örnberg"
                mail="frida.ornberg@clearlyab.se"
                phone="072-897 60 41"
                role="Redovisningskonsult"
              />
              <Employee
                image={data.aida.childImageSharp.fixed}
                role="Redovisningskonsult"
                name="Aida Odenhall Talari"
                mail="aida.odenhall.talari@clearlyab.se"
                phone={"072-891 55 24"}
              />
              <Employee
                image={data.ida.childImageSharp.fixed}
                role="Redovisningskonsult"
                name="Ida Helin"
                mail="ida.helin@clearlyab.se"
                phone={"072-892 13 17"}
              />
              <Employee
                image={data.mikaela.childImageSharp.fixed}
                role="Redovisningskonsult"
                name="Mikaela Nordqvist"
                mail="mikaela.nordqvist@clearlyab.se"
                phone={"072-891 72 28"}
              />
            </div>
          </section>
        )}
      />
    )
  }
}

export default Employees
