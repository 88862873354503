import React from "react"


import Layout from "../components/layout"
import SEO from "../components/seo"
import Jobs from "../components/jobs"
import Contact from "../components/contact"
import Employees from "../components/employees"

const AboutPage = () => (
  <Layout isAbout={true}>
    <SEO title="Om oss" />
    <Employees />
    <Jobs />
    <Contact />
  </Layout>
)



export default AboutPage
