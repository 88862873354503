import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { MobileRule } from "../constants"
import { StaticQuery, graphql } from "gatsby"
/** @jsx jsx */
import { jsx } from "@emotion/core"

const Employee = (props, { siteTitle }) => {
  const { image, name, role, mail, phone } = props

  return (
    <StaticQuery
      query={graphql`
        query {
          placeholder: file(
            relativePath: { eq: "employees/placeholder-employee.jpg" }
          ) {
            childImageSharp {
              fixed(width: 260, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <div
          css={{
            [MobileRule]: {
              width: "auto",
              minWidth: "inherit",
              maxWidth: "200px",
            },
            minWidth: "280px",
            width: "33%",
            marginBottom: "4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Img
            fixed={image ? image : data.placeholder.childImageSharp.fixed}
          />
          <p
            css={{
              color: "#FF8479",
              fontSize: "14px",
              marginBottom: 0,
              fontWeight: 600,
            }}
          >
            {role}
          </p>
          <p css={{ fontWeight: 600, fontSize: "14px", marginTop: "0.25rem" }}>
            {name}
          </p>
          <a
            css={{ textDecoration: "none", color: "#323943", fontSize: "14px" }}
            href={`mailto:${props.mail}`}
          >
            {mail}
          </a>
          <p
            css={{
              ["a"]: {
                textDecoration: "none",
                color: "#323943",
                fontSize: "14px",
              },
              fontSize: "14px",
              marginTop: "0.25rem",
            }}
          >
            <a href={`tel:${phone}`}>{phone}</a>
          </p>
        </div>
      )}
    />
  )
}

export default Employee
